/** @format */

import AWS from "aws-sdk";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
const _ = require("lodash");
const moment_tz = require("moment-timezone");

export const GenerateBase64 = async (imageUrl) => {
   const splitedUrl = imageUrl?.split("/").slice(3).join("/");
   console.log("GenerateBase64", splitedUrl);

   AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
   });
   const s3 = new AWS.S3();

   const getObjectParams = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: splitedUrl,
   };
   const imageData = await s3.getObject(getObjectParams).promise();
   const base64Image = bytesToBase64(imageData.Body);
   const BASE64_STRING = `data:image/svg;base64,${base64Image}`;
   return BASE64_STRING;
   // fetch( imageUrl )
   //     .then( response => response.blob() )
   //     .then( blob => {
   //         const reader = new FileReader();
   //         reader.readAsDataURL( blob );
   //         reader.onloadend = () => {
   //             const base64data = reader.result;
   //             setImageData( base64data );
   //         };
   //     } );
};

export const base64ToFile = (dataurl, filename) => {
   var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
   while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
   }
   return new File([u8arr], filename, { type: mime });
};

export const checkIfArraysAreEqual = (array1, array2) => {
   return _.isEqual(_.sortBy(array1, JSON.stringify), _.sortBy(array2, JSON.stringify));
};

const base64codes = [
   255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
   255, 255, 255, 255, 62, 255, 255, 255, 63, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 255, 255, 255, 0, 255, 255, 255, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
   23, 24, 25, 255, 255, 255, 255, 255, 255, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
];

const base64abc = [
   "A",
   "B",
   "C",
   "D",
   "E",
   "F",
   "G",
   "H",
   "I",
   "J",
   "K",
   "L",
   "M",
   "N",
   "O",
   "P",
   "Q",
   "R",
   "S",
   "T",
   "U",
   "V",
   "W",
   "X",
   "Y",
   "Z",
   "a",
   "b",
   "c",
   "d",
   "e",
   "f",
   "g",
   "h",
   "i",
   "j",
   "k",
   "l",
   "m",
   "n",
   "o",
   "p",
   "q",
   "r",
   "s",
   "t",
   "u",
   "v",
   "w",
   "x",
   "y",
   "z",
   "0",
   "1",
   "2",
   "3",
   "4",
   "5",
   "6",
   "7",
   "8",
   "9",
   "+",
   "/",
];

function bytesToBase64(bytes) {
   let result = "",
      i,
      l = bytes.length;
   for (i = 2; i < l; i += 3) {
      result += base64abc[bytes[i - 2] >> 2];
      result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
      result += base64abc[((bytes[i - 1] & 0x0f) << 2) | (bytes[i] >> 6)];
      result += base64abc[bytes[i] & 0x3f];
   }
   if (i === l + 1) {
      // 1 octet yet to write
      result += base64abc[bytes[i - 2] >> 2];
      result += base64abc[(bytes[i - 2] & 0x03) << 4];
      result += "==";
   }
   if (i === l) {
      // 2 octets yet to write
      result += base64abc[bytes[i - 2] >> 2];
      result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
      result += base64abc[(bytes[i - 1] & 0x0f) << 2];
      result += "=";
   }
   return result;
}

export const checkIfTokenValid = (status) => {
   if (status === 401) {
      localStorage.clear();
      window.location.replace("/");
   } else {
      return true;
   }
};

export const capitalizeFirstLetter = (str) => {
   if (str && typeof str === "string") {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
   }
   return str;
};

export const getCurrentWeekRange = () => {
   const startOfWeek = moment().startOf("week").toDate();
   const endOfWeek = moment().endOf("week").toDate();

   return {
      start_date: startOfWeek,
      end_date: endOfWeek,
   };
};

export const formatDate = (date) => date.toISOString().substring(0, 10);

export const formatMonthDate = (date) => moment(date).format("MMM DD, YYYY");

export const getCurrency = (code) => getSymbolFromCurrency(code);

export const getTimeAgo = (date) => {
   let originalDate = new Date(date);
   let offsetMinutes = new Date().getTimezoneOffset();

   const newDate = adjustDateByTimezoneOffset(originalDate, offsetMinutes);
   return newDate.toISOString();
};

function adjustDateByTimezoneOffset(dateString, offsetMinutes) {
   // Create a Date object from the input date string
   let date = new Date(dateString);

   // Adjust the date by the timezone offset
   date.setMinutes(date.getMinutes() + offsetMinutes);

   return date;
}

export const getUTCTime = (dateString) => {
   var date = new Date(dateString);
   var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
   console.log("getUTCTime", new Date(now_utc));
   return date.toISOString();
};
