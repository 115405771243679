/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpRightFromSquare, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import "@popperjs/core";
import ExportIcon from "assets/icons/ExportIcon";
import "bootstrap";
import Button from "components/Button2";
import Filters from "components/FIlters";
import Loader from "components/Loader";
import NoItems from "components/NoItems";
import RefreshingCounter from "components/RefreshingCounter";
import Search from "components/Search";
import Layout from "layouts";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllCredits } from "store/action/CreditsAction";
import * as OrderActions from "store/action/OrderAction";
import { GetSpecificOrder } from "store/action/OrderAction";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Order from "./Order";
import OrderEdit from "./OrderEdit";
import ordersjson from "./data.json";
import "./style.scss";
import { getCurrency, getTimeAgo, getUTCTime } from "utils/functions";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "react-timeago";
import frenchStrings from "react-timeago/lib/language-strings/fr";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

const formatter = buildFormatter(frenchStrings);

const FILTER_BY_DATE = ["Today", "Yesterday", "This week", "All Time "];
function Orders(props) {
   const orders = useSelector((state) => state.OrderReducer?.Orders);
   const navigate = useNavigate();
   const [oRDeRs, setORDERS] = useState(ordersjson);
   const [searchVal, setSearchVal] = useState("");
   //   const [merchantDetails, setmerchantDetails] = useState(null);
   const [search, setSearch] = useState([]);
   const { order_id } = useParams();
   const [filters, setFilters] = useState({
      order_status: [],
      order_type: [],
      waiterList: [],
      payment: [],
      date: "Today",
      search: "",
   });
   const [order, setOrder] = useState(-1);
   const [loading, setloading] = useState(false);
   const { no_of_credits: creditsAvailable } = useSelector((state) => state.CreditsReducer.credits);
   const [orderTypeValues, setorderTypeValues] = useState([]);
   const [orderStatusValues, setorderStatusValues] = useState([]);
   const [individualOrder, setindividualOrder] = useState({});
   const [waiterNameList, setWaiterNameList] = useState([]);
   const { merchantDetails } = useSelector((state) => state?.MerchantReducer);
   const audioRef = useRef(null);
   const dispatch = useDispatch();

   useEffect(() => {
      FETCH_ORDERS().then(() => {
         fetchCreditsDetails();
      });
   }, []);

   const setOrdersValue = async (arr) => {
      let statusTemp = [...orderStatusValues];
      let typeTemp = [...orderTypeValues];
      let waiterTemp = [...waiterNameList];
      console.log(arr, "arrasxdsacv");
      arr.length !== undefined &&
         arr.map((i) => {
            statusTemp.push(makeUpperCaseAfterUnderscore(i.order_status));
            typeTemp.push(i.order_type);
            waiterTemp.push(i.waiter_name);
            setorderStatusValues(removeDuplicates(statusTemp));
            setorderTypeValues(removeDuplicates(typeTemp));
            setWaiterNameList(removeDuplicates(waiterTemp.filter((i) => i !== "0" && i !== "-")));
         });
   };

   function makeUpperCaseAfterUnderscore(str) {
      return str.replace(/_\s*([a-z])/g, function (d, e) {
         return " " + e.toUpperCase();
      });
   }

   function makeNoSpaceUpperCaseAfterUnderscore(str) {
      let items = str.charAt(0).toLowerCase() + str.slice(1);
      items = items.replace(/\s+/g, "");
      return items;
   }

   console.log(orderTypeValues, "orderTypeValues");

   function RemoveUnderscoreUpperCaseAfterUnderscore(str) {
      return str.replace(/_\s*([a-z])/g, function (d, e) {
         return e.toUpperCase();
      });
   }

   const fetchCreditsDetails = async () => {
      const result = await dispatch(getAllCredits());
   };

   const FETCH_ORDERS = async () => {
      return new Promise(async (resolve, reject) => {
         setloading(true);
         const result = await dispatch(OrderActions.GetAllOrders());
         setloading(false);
         if (result.type === "GetAllOrders/fulfilled") {
            setOrdersValue(result?.payload);
            console.log(result?.payload, "ORDERTYPECHECK");
            resolve();
         } else {
            reject();
         }
      });
   };

   const openDetails = async (order_id) => {
      setloading(true);
      try {
         const result = await dispatch(GetSpecificOrder(order_id));
         console.log("openDetails", result);
         setloading(false);
         if (result.type === "GetSpecificOrder/fulfilled") {
            setindividualOrder(result.payload);
            document.getElementById("side-wrapper").classList.add("open");
            document.getElementById("side-wrapper").classList.remove("close");
         }
      } catch (error) {
         setloading(false);
         console.log("error occurred");
      }
   };

   const handleSearchChange = (event) => {
      setFilters({
         ...filters,
         search: event.target.value,
      });
   };
   const handleOrderStatusChange = (event) => {
      if (event.target.checked) {
         setFilters({
            ...filters,
            order_status: [...filters.order_status, event.target.value],
         });
      } else {
         setFilters({
            ...filters,
            order_status: filters.order_status.filter((status) => status !== event.target.value),
         });
      }
   };
   const handleOrderTypeChange = (event) => {
      if (event.target.checked) {
         setFilters({
            ...filters,
            order_type: [...filters.order_type, event.target.value],
         });
      } else {
         setFilters({
            ...filters,
            order_type: filters.order_type.filter((type) => type !== event.target.value),
         });
      }
   };

   const handleWaiterChange = (event) => {
      if (event.target.checked) {
         setFilters({
            ...filters,
            waiterList: [...filters.waiterList, event.target.value],
         });
      } else {
         setFilters({
            ...filters,
            waiterList: filters.waiterList.filter((waiter) => waiter !== event.target.value),
         });
      }
   };

   const handlePaymentChange = (event) => {
      if (event.target.checked) {
         setFilters({
            ...filters,
            payment: [...filters.payment, event.target.value],
         });
      } else {
         setFilters({
            ...filters,
            payment: filters.payment.filter((payment) => payment !== event.target.value),
         });
      }
   };
   const searchOrders = (input) => {
      let temp = [];
      setSearchVal(input);
      if (input !== "") {
         for (const i in orders) {
            console.log("ORDEEER", orders[i].order_id.includes(input), orders[i]?.order_id);
            if (orders[i].order_id.includes(input)) {
               console.log("ORDEEER PUSHED", orders[i].order_id.includes(input), orders[i]?.order_id);
               temp.push(orders[i]);
            }
         }
         console.log("ORDEEER TEMP", temp);
         if (temp.length > 0) {
            setSearch(temp);
         } else {
            setSearch([]);
         }
      }
   };

   console.log(search, "ORDEEER T SE");

   const handleDateChange = (event) => {
      setFilters({
         ...filters,
         date: event.target.value,
      });
   };

   function removeDuplicates(arr) {
      let unique = [];
      for (const i in arr) {
         if (unique.indexOf(arr[i]) === -1) {
            unique.push(arr[i]);
         }
      }
      return unique;
   }

   let filteredOrders =
      orders?.length !== undefined &&
      orders?.filter((order) => {
         if (filters.order_status?.length > 0) {
            return filters.order_status?.includes(makeUpperCaseAfterUnderscore(order?.order_status));
         } else {
            return order?.order_status !== "order_completed";
         }
      });
   filteredOrders =
      filteredOrders?.length !== undefined &&
      filteredOrders?.filter((order) => {
         if (filters.waiterList?.length > 0) {
            return filters.waiterList.includes(order.waiter_name);
         }
         return true;
      });
   filteredOrders =
      filteredOrders?.length !== undefined &&
      filteredOrders?.filter((order) => {
         if (filters?.order_type?.length > 0) {
            return filters?.order_type?.includes(order.order_type);
         }
         return true;
      });
   filteredOrders =
      filteredOrders?.length !== undefined &&
      filteredOrders?.filter((order) => {
         if (filters.payment?.length > 0) {
            return filters.payment?.includes(order.payment_status);
         }
         return true;
      });
   filteredOrders =
      filteredOrders?.length !== undefined &&
      filteredOrders?.filter((order) => {
         if (filters.date?.length > 0) {
            const date = new Date(order?.order_date);
            const filter = new Date();
            const datestr = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            switch (filters?.date) {
               case "Today":
                  filter.getDate();
                  break;
               case "Yesterday":
                  filter.setDate(filter.getDate() - 1);
                  break;
               case "All Time":
                  return true;
               case "This week":
                  return filter.getTime() - 7 * 24 * 60 * 60 * 1000 <= date.getTime();
                  break;
               default:
                  return true;
            }
            return datestr === `${filter.getFullYear()}-${filter.getMonth() + 1}-${filter.getDate()}`;
         }
         return true;
      });
   filteredOrders =
      filteredOrders?.length !== undefined &&
      filteredOrders?.filter((order) => {
         if (filters?.search.length > 0) {
            return order.id.toLowerCase().includes(filters.search.toLowerCase().trim()) || order.customer_name.toLowerCase().includes(filters.search.toLowerCase().trim());
         }
         return true;
      });

   const handleFiltersCount = () => {
      let count = 0;
      if (filters?.order_status?.length > 0) {
         count += filters?.order_status?.length;
      }
      if (filters?.order_type?.length > 0) {
         count += filters?.order_type?.length;
      }
      if (filters?.payment?.length > 0) {
         count += filters?.payment?.length;
      }
      return count;
   };

   const clearFilters = () => {
      setFilters({
         ...filters,
         order_status: [],
         order_type: [],
         payment: [],
      });
   };

   const handleExportOrder = async () => {
      setloading(true);
      const result = await dispatch(OrderActions.ExportOrder());
      if (result?.type === "ExportOrder/fulfilled") {
         toast.success(result?.payload?.response);
      }
      setloading(false);
   };

   const goToManualOrder = () => {
      navigate("/orders/create");
   };

   useEffect(() => {
      FETCH_ORDERS().then(() => {
         order_id !== undefined && openDetails(order_id);
      });
   }, [order_id]);

   const getDefaultOrders = () => {
      if (filters.order_status.length > 1) {
         return filteredOrders;
      }

      return orders?.filter((order) => {
         if (order?.order_status !== "order_completed") {
            return order;
         }
      });
   };

   console.log("filter ORDERS", filters);

   return (
      <>
         <Loader isLoading={loading} />
         <Layout>
            <Order
               setloading={setloading}
               loading={loading}
               order={individualOrder}
               setOrders={setORDERS}
               orders={orders}
            />
            <OrderEdit
               order={orders[order]}
               setOrders={setORDERS}
               orders={orders}
            />
            <div
               id="tablediv"
               className="my-3 p-3">
               <div className="orders-header-btn-wrapper">
                  <div className="order-header-refresh-btn">
                     <div className="fw-bold fs-4 ">All orders</div>
                     <RefreshingCounter
                        action={() => {
                           FETCH_ORDERS();
                           fetchCreditsDetails();
                        }}
                        duration={30}
                     />
                  </div>
                  <div className="buttons-container">
                     {/* <button
                        className={"button2Container Btn2sm"}
                        style={{
                           backgroundColor: creditsAvailable <= 20 ? "#D90429" : creditsAvailable <= 50 ? "orange" : "#2FBF71",
                           border: "none",
                           color: "#fff",
                           width: "auto",
                           padding: "0.5rem 1rem",
                        }}>
                        Available credits : <div style={{ fontWeight: "bold" }}>{creditsAvailable} </div>
                     </button> */}
                     <Button
                        border={"none"}
                        color={"#fff"}
                        Size={"sm"}
                        width={"10rem"}
                        background={"#2D6B4D"}
                        icon={<AddIcon />}
                        title={"Create order"}
                        onClick={goToManualOrder}
                     />
                     <Button
                        border={"none"}
                        color={"#fff"}
                        Size={"sm"}
                        background={"#4361EE"}
                        icon={<ExportIcon />}
                        title={"Export"}
                        onClick={handleExportOrder}
                        disabled={orders?.length > 0 ? false : true}
                     />
                  </div>
               </div>
               <div
                  id="tabletop"
                  className="d-flex justify-content-evenly my-3">
                  <Search
                     height={"2.4rem"}
                     border={"0.5px solid rgba(0,0,0,0.3)"}
                     width={"90%"}
                     onChange={(e) => {
                        searchOrders(e.target.value);
                     }}
                     placeholder={"Search in orders"}
                  />
                  <div className="dropdown mx-3 ms-5">
                     <Filters
                        count={handleFiltersCount}
                        clearFilters={clearFilters}
                     />
                     <div className="dropdown-menu p-3">
                        <div>
                           <div>Order Status</div>
                           {orderStatusValues.length !== undefined &&
                              orderStatusValues?.map((i) => (
                                 <div>
                                    <input
                                       className="form-check-input"
                                       type="checkbox"
                                       value={i}
                                       id={`main-${i}`}
                                       onChange={handleOrderStatusChange}
                                       checked={filters.order_status.includes(i)}
                                    />
                                    <label
                                       className="form-check-label text-capitalize ps-1"
                                       htmlFor={`main-${i}`}>
                                       {i}
                                    </label>
                                 </div>
                              ))}
                        </div>
                        <div>
                           <div>Payment</div>
                           <div>
                              <input
                                 className="form-check-input"
                                 type="checkbox"
                                 value="paid"
                                 id="main-paid"
                                 onChange={handlePaymentChange}
                                 checked={filters.payment.includes("paid")}
                              />
                              <label
                                 className="form-check-label ms-1"
                                 htmlFor="main-paid">
                                 Paid
                              </label>
                           </div>
                           <div>
                              <input
                                 className="form-check-input"
                                 type="checkbox"
                                 value="un-paid"
                                 id="main-un-paid"
                                 onChange={handlePaymentChange}
                                 checked={filters.payment.includes("un-paid")}
                              />
                              <label
                                 className="form-check-label ms-1"
                                 htmlFor="main-un-paid">
                                 Un-Paid
                              </label>
                           </div>
                        </div>{" "}
                        {/* <div>
                  <div>Payment method</div>
                </div> */}
                        <div>
                           <div>Order Type</div>
                           {orderTypeValues?.map((i) => (
                              <div>
                                 <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={i}
                                    id={`main-${i}`}
                                    onChange={handleOrderTypeChange}
                                    checked={filters.order_type.includes(i)}
                                 />
                                 <label
                                    className="form-check-label ms-1"
                                    htmlFor={`main-${i}`}>
                                    {i}
                                 </label>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>

                  <div className="dropdown mx-3 flex-grow-1">
                     <button
                        className="dropdown-toggle w-100 text-start d-flex align-items-center"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="filter-date">
                        <FontAwesomeIcon icon={faCalendarDays} />
                        &nbsp;&nbsp;{filters.date}
                        <FontAwesomeIcon
                           className="ms-auto"
                           icon={faChevronDown}
                        />
                     </button>
                     <div className="dropdown-menu p-3">
                        <div>
                           {FILTER_BY_DATE?.map((i) => (
                              <div>
                                 <div>
                                    <input
                                       className="form-check-input"
                                       type="checkbox"
                                       value={i}
                                       id={`${i}`}
                                       onChange={handleDateChange}
                                       checked={filters.date === i}
                                    />
                                    <label
                                       className="form-check-label text-capitalize ps-1"
                                       htmlFor={`${i}`}>
                                       {i}
                                    </label>
                                 </div>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  className="px-2  tbodyContainer "
                  id="table-wrapper">
                  <table className="w-100 ">
                     <thead className=" tHeadContainer">
                        <tr className="text-center">
                           <th className="">Order ID</th>
                           <th>Table no</th>
                           {/* <th>Customer Name</th> */}
                           <th id="waiter name">
                              <div className="dropdown">
                                 <button
                                    className="dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    Waiter Name
                                 </button>
                                 {waiterNameList?.length > 0 && (
                                    <div className="dropdown-menu p-3">
                                       {waiterNameList?.map((i) => (
                                          <div>
                                             <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={i}
                                                id={`order-type-${i}`}
                                                onChange={handleWaiterChange}
                                                checked={filters?.waiterList?.includes(i)}
                                             />
                                             <label
                                                className={`form-check-label ms-1 ${i} `}
                                                htmlFor={`order-type-${i}`}>
                                                {i}
                                             </label>
                                          </div>
                                       ))}
                                    </div>
                                 )}
                              </div>
                           </th>
                           <th>
                              <div className="dropdown">
                                 <button
                                    className="dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    Order Type
                                 </button>
                                 <div className="dropdown-menu p-3">
                                    {orderTypeValues?.map((i) => (
                                       <div>
                                          <input
                                             className="form-check-input"
                                             type="checkbox"
                                             value={i}
                                             id={`order-type-${i}`}
                                             onChange={handleOrderTypeChange}
                                             checked={filters?.order_type?.includes(i)}
                                          />
                                          <label
                                             className={`form-check-label ms-1 ${i} `}
                                             htmlFor={`order-type-${i}`}>
                                             {i}
                                          </label>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </th>
                           <th id="order-status">
                              <div className="dropdown">
                                 <button
                                    className="dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    Order Status
                                 </button>
                                 <div className="dropdown-menu p-3">
                                    {orderStatusValues?.map((i) => {
                                       const spanClass = makeNoSpaceUpperCaseAfterUnderscore(i);
                                       return (
                                          <div className="d-flex align-items-center">
                                             <input
                                                className="form-check-input m-0"
                                                type="checkbox"
                                                value={i}
                                                id={`order-status-${i}`}
                                                onChange={handleOrderStatusChange}
                                                checked={filters.order_status.includes(i)}
                                             />
                                             <label
                                                className={`form-check-label text-capitalize  ms-1 mb-1 ${spanClass}`}
                                                htmlFor={`order-status-${i}`}>
                                                {i}
                                             </label>
                                          </div>
                                       );
                                    })}
                                 </div>
                              </div>
                           </th>
                           <th id="Total Amount">
                              <p className="text-[1rem] text-gray-500 mr-2">Total Amount</p>
                           </th>
                           <th id="payment">
                              <div className="dropdown">
                                 <button
                                    className="dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    Payment
                                 </button>
                                 <div className="dropdown-menu p-3">
                                    <div className="d-flex align-items-center">
                                       <input
                                          className="form-check-input m-0"
                                          type="checkbox"
                                          value="paid"
                                          id="payment-paid"
                                          onChange={handlePaymentChange}
                                          checked={filters.payment.includes("paid")}
                                       />
                                       <label
                                          className="form-check-label ms-1 mb-1 Paid"
                                          htmlFor="payment-paid">
                                          Paid
                                       </label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                       <input
                                          className="form-check-input m-0"
                                          type="checkbox"
                                          value="un-paid"
                                          id="payment-un-paid"
                                          onChange={handlePaymentChange}
                                          checked={filters.payment.includes("un-paid")}
                                       />
                                       <label
                                          className="form-check-label ms-1 Un-Paid"
                                          htmlFor="payment-un-paid">
                                          Un-Paid
                                       </label>
                                    </div>
                                 </div>
                              </div>
                           </th>
                           <th>Date</th>
                           <th className="text-center">View Order</th>
                        </tr>
                     </thead>
                     <tbody className="tbodyContainer">
                        {orders.length > 0 || search?.length > 0 ? (
                           searchVal != "" ? (
                              search.map((order, index) => {
                                 let spanClass = RemoveUnderscoreUpperCaseAfterUnderscore(order.order_status);
                                 let d = new Date(order.datetime);
                                 let date_options = {
                                    day: "numeric",
                                    year: "numeric",
                                    month: "short",
                                 };
                                 let time_options = {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                 };
                                 return (
                                    <tr
                                       className="text-center"
                                       key={order.id}
                                       onClick={() => {
                                          openDetails(order.order_id);
                                       }}>
                                       <td
                                          data-id={index}
                                          style={{
                                             width: "8%",
                                          }}>
                                          <a
                                             style={{
                                                cursor: "pointer",
                                                color: "#0d6efd",
                                             }}>
                                             {order.order_id}
                                          </a>
                                       </td>
                                       <td
                                          style={{
                                             width: "7%",
                                          }}>
                                          {order?.table_number !== "Unknown" ? order?.table_number : "-"}
                                       </td>
                                       <td className="font-semibold">{order.waiter_name === "0" ? "-" : order.waiter_name}</td>
                                       <td
                                          style={{
                                             width: "10%",
                                          }}
                                          className={order.order_type}>
                                          {order.order_type}
                                       </td>
                                       <td
                                          style={{
                                             width: "25%",
                                          }}>
                                          <span className={spanClass}>{makeUpperCaseAfterUnderscore(order.order_status)}</span>
                                       </td>
                                       <td
                                          className="font-semibold"
                                          style={{
                                             width: "15%",
                                          }}>
                                          {getCurrency(merchantDetails?.currency)} {order.total_amount_after_discount}
                                       </td>
                                       <td
                                          style={{
                                             width: "5%",
                                          }}>
                                          <span className={order.payment_status === "paid" && "Paid" ? "Paid" : "Un-Paid"}>{order.payment_status === "paid" && "Paid" ? "Paid" : "Un Paid"}</span>
                                       </td>{" "}
                                       <td
                                          style={{
                                             width: "15%",
                                          }}>
                                          <ReactTimeAgo
                                             date={getTimeAgo(order?.order_date)}
                                             locale="en-US"
                                          />
                                       </td>
                                       <td
                                          style={{
                                             width: "10%",
                                          }}
                                          className="text-center"
                                          data-id={index}>
                                          <td
                                             style={{
                                                width: "10%",
                                             }}
                                             className="text-center"
                                             data-id={index}>
                                             <button onClick={openDetails}>
                                                <FontAwesomeIcon
                                                   style={{ pointerEvents: "none" }}
                                                   icon={faArrowUpRightFromSquare}
                                                />
                                             </button>
                                          </td>
                                       </td>
                                    </tr>
                                 );
                              })
                           ) : filteredOrders?.length !== 0 ? (
                              filteredOrders?.map((order, index) => {
                                 let spanClass = RemoveUnderscoreUpperCaseAfterUnderscore(order.order_status);
                                 return (
                                    <tr
                                       className="text-center"
                                       key={order.id}
                                       onClick={() => {
                                          openDetails(order.order_id);
                                       }}>
                                       <td
                                          data-id={index}
                                          style={{
                                             width: "8%",
                                          }}>
                                          <a
                                             style={{
                                                cursor: "pointer",
                                                color: "#0d6efd",
                                             }}>
                                             {order.order_id}
                                          </a>
                                       </td>
                                       <td
                                          style={{
                                             width: "7%",
                                          }}>
                                          {order?.table_number !== "Unknown" ? order?.table_number : "-"}
                                       </td>
                                       <td className="font-semibold">{order.waiter_name === "0" ? "-" : order.waiter_name}</td>
                                       <td
                                          style={{
                                             width: "10%",
                                          }}
                                          className={order.order_type}>
                                          {order.order_type}
                                       </td>
                                       <td
                                          style={{
                                             width: "25%",
                                          }}>
                                          <span className={spanClass}>{makeUpperCaseAfterUnderscore(order.order_status)}</span>
                                       </td>
                                       <td
                                          className="font-semibold"
                                          style={{
                                             width: "15%",
                                          }}>
                                          {getCurrency(merchantDetails?.currency)} {order.total_amount_after_discount}
                                       </td>
                                       <td
                                          style={{
                                             width: "5%",
                                          }}>
                                          <span className={order.payment_status === "paid" && "Paid" ? "Paid" : "Un-Paid"}>{order.payment_status === "paid" && "Paid" ? "Paid" : "Un Paid"}</span>
                                       </td>{" "}
                                       <td
                                          style={{
                                             width: "15%",
                                          }}>
                                          <ReactTimeAgo
                                             date={getTimeAgo(order?.order_date)}
                                             locale="en-US"
                                          />
                                       </td>
                                       <td
                                          style={{
                                             width: "10%",
                                          }}
                                          className="text-center"
                                          data-id={index}>
                                          <td
                                             style={{
                                                width: "10%",
                                             }}
                                             className="text-center"
                                             data-id={index}>
                                             <button onClick={openDetails}>
                                                <FontAwesomeIcon
                                                   style={{ pointerEvents: "none" }}
                                                   icon={faArrowUpRightFromSquare}
                                                />
                                             </button>
                                          </td>
                                       </td>
                                    </tr>
                                 );
                              })
                           ) : (
                              <div className="orders-no-items-container">
                                 <NoItems
                                    title={"No Orders"}
                                    description={"No orders to display!"}
                                 />
                              </div>
                           )
                        ) : (
                           <div className="orders-no-items-container">
                              <NoItems
                                 title={"No Orders"}
                                 description={"No orders to display!"}
                              />
                           </div>
                        )}
                     </tbody>
                  </table>
               </div>
            </div>
         </Layout>
      </>
   );
}

export default Orders;
