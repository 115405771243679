/** @format */

import InfoIcon from "@mui/icons-material/Info";
import { Alert, Box, Snackbar, Tab, Tabs, Tooltip } from "@mui/material";
import EditIcon from "assets/icons/EditIcon";
import axios from "axios";
import Button from "components/Button2";
import { DialogBox } from "components/DialogBox";
import Input from "components/FormikInput";
import ImageUploader from "components/ImageUploader";
import Loader from "components/Loader";
import NotificationsWindow from "components/NotificationsWindow";
import { ToggleSwitch } from "components/ToggleSwitch";
import { Form, Formik } from "formik";
import Layout from "layouts";
import moment from "moment/moment";
import { TabPanel } from "pages/Analytics";
import SubscriptionEndedModal from "pages/Subscription/EndedModal";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MdOutlineContentCopy } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as MerchantActions from "store/action/MerchantAction";
import { GetCurrentSubscription, GetSubscription } from "store/action/SubscriptionAction";
import * as yup from "yup";
import "./index.scss";
import ContactUsModal from "components/ContactUsModal";
import { getCountryCode } from "assets/data/Countries";
import countryToCurrency from "country-to-currency";
import StoreWrapper from "components/StoreWrapper";

const MERCHANT_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/pincode/get_pincode_info`;

function Profile() {
   const [Merchant, setMerchant] = useState(false);
   const [isVisible, setVisible] = useState(false);
   const [loading, setloading] = useState(false);
   const [StateName, setStateName] = useState("");
   const [showContactusModal, setshowContactusModal] = useState(false);
   const [value, setvalue] = useState(0);
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);
   const isNewUser = useSelector((state) => state.AuthReducer?.otpDetails);
   const [notificationsVisible, setNotificationsVisible] = useState(false);
   const [isLocationBlocked, setisLocationBlocked] = useState(false);
   const [turnOnLocationMessage, setturnOnLocationMessage] = useState(false);

   const [geolocation, setGeolocation] = useState({
      latitude: 0,
      longitude: 0,
   });
   const notifications = [
      {
         id: 1,
         icon: "https://cdn-icons-png.flaticon.com/512/2922/2922510.png",
         title: "Your password has been successfully changed",
         read: false,
         dateTime: new Date(),
         sender: null,
      },
      {
         id: 2,
         icon: "https://cdn-icons-png.flaticon.com/512/2922/2922510.png",
         title: "Thank you for booking a meeting with us",
         read: false,
         dateTime: new Date("2021-09-10T12:00:00"),
         sender: "Kevin Dukkon",
      },
      {
         id: 3,
         icon: "https://cdn-icons-png.flaticon.com/512/2922/2922510.png",
         title: "Great news! We are launching our 5th fund: DLE Senior living",
         read: false,
         dateTime: new Date("2021-09-10T12:00:00"),
         sender: "Markus Gavrilov",
      },
   ];

   const getTime = (date) => {
      console.log("GET TIME FORMATe ", date);
      const dd = new Date(date);
      var dateTime = new Date(date);
      var hours = dateTime.getHours();
      var minutes = dateTime.getMinutes();
      // hours = hours % 12;
      hours = hours < 10 ? "0" + hours : hours; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return strTime;
   };

   const [initialValues, setinitialValues] = useState({
      restaurant_name: merchantDetails?.restaurant_name,
      phone_number: merchantDetails?.phone_number,
      email: merchantDetails?.email,
      pincode: merchantDetails?.pincode,
      address: merchantDetails?.address,
      image: merchantDetails?.image_url,
      opening_time: getTime(merchantDetails?.opening_time),
      closing_time: getTime(merchantDetails?.closing_time),
      accept_orders: merchantDetails?.accept_orders,
      dine_in: merchantDetails?.dine_in,
      take_away: merchantDetails?.take_away,
      delivery: merchantDetails?.delivery,
      auto_confirm: merchantDetails?.auto_confirm,
      show_available_item_count: merchantDetails?.show_available_item_count,
      instagram_url: merchantDetails?.instagram_url,
      facebook_page_url: merchantDetails?.facebook_page_url,
      twitter_url: merchantDetails?.twitter_url,
      youtube_url: merchantDetails?.youtube_url,
      google_reviews_link: merchantDetails?.google_reviews_link,
      delivery_radius: merchantDetails?.delivery_radius,
      image_url: merchantDetails?.image_url,
      image: merchantDetails?.image,
      latitude: merchantDetails?.latitude,
      longitude: merchantDetails?.longitude,
      include_gst: merchantDetails?.include_gst,
      about_url: merchantDetails?.about_url,
      currency: countryToCurrency[getCountryCode(merchantDetails?.country)],
      whatsapp_url: merchantDetails?.whatsapp_url,
   });

   const dispatch = useDispatch();
   const subscriptionDetails = useSelector((state) => state.SubscriptionReducer.currentSubscription);
   const GET_MERCHANT_DETAILS = async () => {
      setloading(true);
      const result = await dispatch(MerchantActions.GetMerchant());
      setinitialValues({
         ...result.payload,
         // opening_time: getTime( result.payload?.opening_time ),
         opening_time: getTime(result.payload?.opening_time),
         closing_time: getTime(result.payload?.closing_time),
      });
      setloading(false);
   };
   const GET_SUBSCRIPTION_DETAILS = async () => {
      await dispatch(GetSubscription());
      await dispatch(GetCurrentSubscription());

      // console.log(result, 'GET_SUBSCRIPTION_DETAILS');
   };
   useEffect(() => {
      GET_MERCHANT_DETAILS();
      GET_SUBSCRIPTION_DETAILS();
   }, []);

   console.log(initialValues, "initialValues");
   const getLocation = async (pincode) => {
      if (pincode?.length === 6) {
         try {
            const response = await axios.post(
               MERCHANT_API_ENDPOINT,
               {
                  country: "in",
                  pincode: pincode,
               },
               {
                  headers: {
                     "Content-Type": "application/json",
                  },
               },
            );

            console.log("PINCODE DETAILS", response.data);
            if (response.status === 200 || response.status === 201) {
               const resData = await response.data;
               console.log("GOT PINCODE DETAILS");
               setStateName(resData?.state_name);
               return resData;
            }
         } catch (error) {
            throw error;
         }
      }
   };
   const validationSchema = yup.object({
      restaurant_name: yup.string().typeError("").required("Field is Required").nullable(),
      phone_number: yup.number().required("Field is Required").nullable(),
      email: yup.string().typeError("").email("Enter a valid email").required("Field is Required").nullable(),
      pincode: yup.string().required("Field is Required ").nullable(),
      address: yup.string().typeError("").required("Field is Required").nullable(),
   });

   const onSubmit = async (values) => {
      console.log("VALUESSETTIGN", values);
      setloading(true);

      // Conditionally set values based on show_menu_only
      const showMenuOnly = values?.show_menu_only;
      const accept_orders = showMenuOnly ? false : values?.accept_orders;
      const include_gst = showMenuOnly ? false : values?.include_gst;
      const show_available_item_count = showMenuOnly ? false : values?.show_available_item_count;
      const dine_in = showMenuOnly ? false : values?.dine_in;
      const take_away = showMenuOnly ? false : values?.take_away;

      const body = {
         restaurant_name: values?.restaurant_name,
         phone_number: values?.phone_number,
         pincode: values?.pincode.toString(),
         address: values?.address,
         email: values?.email,
         username: values?.restaurant_name + new Date().toISOString(),
         state: StateName,
         country: values?.country,
         accept_orders: accept_orders,
         dine_in: dine_in,
         take_away: take_away,
         delivery: values?.delivery,
         show_menu_only: showMenuOnly,
         opening_time: moment(values?.opening_time, "HH:mm").toISOString(),
         closing_time: moment(values?.closing_time, "HH:mm").toISOString(),
         auto_confirm: values?.auto_confirm,
         show_available_item_count: show_available_item_count,
         instagram_url: values?.instagram_url,
         facebook_page_url: values?.facebook_page_url,
         twitter_url: values?.twitter_url,
         youtube_url: values?.youtube_url,
         google_reviews_link: values?.google_reviews_link,
         latitude: values?.latitude === undefined || values?.latitude === "" ? geolocation?.latitude : values?.latitude,
         longitude: values?.longitude === undefined || values?.longitude === "" ? geolocation?.longitude : values?.longitude,
         delivery_radius: values?.delivery_radius !== null ? parseInt(values?.delivery_radius) : null,
         image: values?.image,
         image_url: values?.image_url,
         include_gst: include_gst,
         currency: countryToCurrency[getCountryCode(merchantDetails.country)?.toUpperCase()],
         about_url: values?.about_url,
         whatsapp_url: values?.whatsapp_url,
      };

      console.log("Merchant Details BODYYY", body);
      await dispatch(MerchantActions.UpdateMerchant(body));

      setloading(false);
      if (isNewUser?.new_user) {
         window.location.reload();
      }
      toast.success("Profile updated successfully");
   };

   const getGeoLocation = async () => {
      navigator.permissions.query({ name: "geolocation" }).then(function (result) {
         if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(function (position) {
               console.log("GRAANTED");
               console.log("Latitude is :", position.coords.latitude);
               console.log("Longitude is :", position.coords.longitude);
               setGeolocation({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
               });
               setinitialValues({
                  ...initialValues,
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  delivery: true,
               });
            });
         } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(function (position) {
               console.log("PROMT");
               console.log("Latitude is :", position.coords.latitude);
               console.log("Longitude is :", position.coords.longitude);
               setGeolocation({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
               });
               setinitialValues({
                  ...initialValues,
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  delivery: true,
               });
            });
         } else if (result.state === "denied") {
            // no-op
            alert("Please enable location services on your browser settings");
         }
         result.onchange = function () {
            console.log(result.state);
         };
      });
   };
   const openShare = () => {
      navigator.clipboard.writeText(merchantDetails?.merchant_url);

      toast.success("Url copied to clipboard");
   };
   const handleChange = (event, newValue) => {
      setvalue(newValue);
   };

   const isLocationPermissionBlocked = async () => {
      navigator.permissions.query({ name: "geolocation" }).then(function (result) {
         if (result.state !== "granted") {
            // no-op
            setisLocationBlocked(true);
         }
      });
   };

   function a11yProps(index) {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   }

   useEffect(() => {
      isLocationPermissionBlocked();
   }, []);

   console.log("Initail Values", initialValues);

   return (
      <Layout>
         <SubscriptionEndedModal display={subscriptionDetails?.plan_remaining_days === 0 ? true : false} />
         <Loader isLoading={loading} />
         <ContactUsModal
            visible={showContactusModal}
            onClose={() => setshowContactusModal(false)}
         />
         <div className="ProfileContainer min-h-[96vh]">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
               <div className="ProfileHeader">
                  <span
                     style={{
                        marginRight: "auto",
                     }}>
                     Merchant settings
                  </span>
                  <Button
                     Size="md"
                     title={`Copy store link`}
                     icon={<MdOutlineContentCopy />}
                     border={"1px solid #C3C5C6"}
                     color={"#2D6B4D"}
                     fontWeight="bold"
                     onClick={() => {
                        openShare();
                     }}
                  />
                  <NotificationsWindow
                     notifications={notifications}
                     isVisible={notificationsVisible}
                     setVisibility={setNotificationsVisible}
                  />
               </div>
               <div className="flex justify-between items-end">
                  <Tabs
                     value={value}
                     onChange={handleChange}
                     TabIndicatorProps={{
                        style: {
                           background: "#2D6B4D",
                        },
                     }}
                     // textColor="secondary"
                     aria-label="basic tabs example">
                     <Tab
                        className=""
                        label="Profile"
                        {...a11yProps(0)}
                        classes={{ root: "tab-text", selected: "tab-text-selected" }}
                     />
                     <Tab
                        className=""
                        label="Store"
                        {...a11yProps(3)}
                        classes={{ root: "tab-text", selected: "tab-text-selected" }}
                     />
                     <Tab
                        className=""
                        label="Social media handles"
                        {...a11yProps(1)}
                        classes={{ root: "tab-text", selected: "tab-text-selected" }}
                     />{" "}
                     {/* {merchantDetails?.country === "India" && (
                        <Tab
                           className="tab-text"
                           label="Delivery"
                           {...a11yProps(2)}
                           classes={{ root: "tab-text", selected: "tab-text-selected" }}
                        />
                     )} */}
                  </Tabs>
                  <h6
                     onClick={() => setshowContactusModal(true)}
                     className="hover:underline text-[grey] hover:text-[#000] cursor-pointer">
                     Contact us for support
                  </h6>
               </div>
            </Box>

            <Formik
               initialValues={initialValues}
               validationSchema={validationSchema}
               onSubmit={onSubmit}
               enableReinitialize={true}>
               {({ isValid, setFieldValue, values }) => {
                  console.log("VALUESPROFILE ", values);

                  return (
                     <Form className="py-5">
                        <TabPanel
                           value={value}
                           index={0}>
                           <DialogBox
                              open={isVisible}
                              handleClose={() => {
                                 setVisible(false);
                              }}
                              autoConfirm={true}
                              title={"Are you sure you want to continue?"}
                              content={"Once enabled, any order you receive will be auto confirmed."}
                              handleContinue={() => {
                                 setFieldValue("auto_confirm", true);
                                 setVisible(false);
                              }}
                           />
                           <div className="FormGroup">
                              <Input
                                 label={"Restaurant name *"}
                                 name="restaurant_name"
                                 onChange={(e) => {
                                    setFieldValue("restaurant_name", e.target.value);
                                 }}
                                 placeholder="Enter restaurant name"
                              />
                              <Input
                                 disabled={merchantDetails?.phone_number === "" || merchantDetails?.phone_number === null || merchantDetails?.phone_number === undefined ? false : true}
                                 label={"Restaurant phone number *"}
                                 name="phone_number"
                                 type="tel"
                                 placeholder="Enter restaurant phone number"
                                 onChange={(e) => {
                                    setFieldValue("phone_number", e.target.value);
                                 }}
                              />
                              <Input
                                 disabled={merchantDetails?.email === "" || merchantDetails?.email === null || merchantDetails?.email === undefined ? false : true}
                                 label={"Email address *"}
                                 name="email"
                                 placeholder="Enter email address"
                              />
                              <div className="flex gap-[2rem] w-full">
                                 <div className="w-[50%]">
                                    <Input
                                       disabled={true}
                                       label={"Country"}
                                       name="country"
                                       placeholder="Enter your country"
                                    />
                                 </div>
                                 <div className="w-[50%]">
                                    <Input
                                       label={"Pincode *"}
                                       onChange={(e) => {
                                          setFieldValue("pincode", e.target.value);
                                          getLocation(values.pincode);
                                       }}
                                       placeholder="Enter pincode"
                                       name="pincode"
                                    />
                                 </div>
                              </div>
                              <Input
                                 label={"Shop opening time *"}
                                 name="opening_time"
                                 placeholder="Enter opening time"
                                 type="time"
                                 onChange={(e) => {
                                    setFieldValue("opening_time", e.target.value);
                                 }}
                              />
                              <Input
                                 label={"Shop closing time *"}
                                 onChange={(e) => {
                                    setFieldValue("closing_time", e.target.value);
                                 }}
                                 placeholder="Enter closing time"
                                 name="closing_time"
                                 type="time"
                              />

                              {/* <div className="geolocations-wrapper">
                      <div className="geolocations">
                        <div className="geolocations-label">Latitude</div>
                        <div className="geolocations-value">{ merchantDetails?.latitude }</div>
                      </div>
                      <div className="geolocations">
                        <div className="geolocations-label">Longitude</div>
                        <div className="geolocations-value">{ merchantDetails?.longitude }</div>
                      </div>
                    </div>*/}

                              <Input
                                 label={"Restaurant address *"}
                                 name="address"
                                 placeholder="Enter restaurant address"
                                 onChange={(e) => {
                                    setFieldValue("address", e.target.value);
                                 }}
                                 textArea
                                 Width="100%"
                              />

                              <ImageUploader
                                 optional
                                 label={"Upload logo"}
                                 setField={setFieldValue}
                                 isEdit={values.image_url === undefined || values.image_url === null ? false : true}
                                 prevValue={values.image_url}
                                 value={values.image}
                              />
                           </div>
                        </TabPanel>
                        <TabPanel
                           value={value}
                           index={2}>
                           <div className="FormGroup">
                              <Input
                                 label={"Instagram handle link"}
                                 name="instagram_url"
                                 placeholder="Enter your instagram link"
                                 onChange={(e) => {
                                    setFieldValue("instagram_url", e.target.value);
                                 }}
                                 text
                              />
                              <Input
                                 label={"Facebook page link"}
                                 name="facebook_page_url"
                                 placeholder="Enter your Facebook page link"
                                 onChange={(e) => {
                                    setFieldValue("facebook_page_url", e.target.value);
                                 }}
                                 text
                              />
                              <Input
                                 label={"Twitter profile link"}
                                 name="twitter_url"
                                 placeholder="Enter your Twitter profile link"
                                 onChange={(e) => {
                                    setFieldValue("twitter_url", e.target.value);
                                 }}
                                 text
                              />
                              <Input
                                 label={"Google review URL"}
                                 name="google_reviews_link"
                                 placeholder="Enter your Google review URL"
                                 onChange={(e) => {
                                    setFieldValue("google_reviews_link", e.target.value);
                                 }}
                                 text
                              />
                              <Input
                                 label={"Youtube URL"}
                                 name="youtube_url"
                                 placeholder="Enter your Youtube page URL"
                                 onChange={(e) => {
                                    setFieldValue("youtube_url", e.target.value);
                                 }}
                                 text
                              />
                              <Input
                                 label={"Whatsapp URL"}
                                 name="whatsapp_url"
                                 placeholder="Enter your Whatsapp URL"
                                 onChange={(e) => {
                                    setFieldValue("whatsapp_url", e.target.value);
                                 }}
                                 text
                              />
                           </div>
                        </TabPanel>
                        <TabPanel
                           value={value}
                           index={3}>
                           <Snackbar
                              style={{ marginLeft: "2rem" }}
                              anchorOrigin={{
                                 horizontal: "center",
                                 vertical: "top",
                              }}
                              open={turnOnLocationMessage}
                              autoHideDuration={6000}
                              onClose={() => setturnOnLocationMessage(false)}>
                              <Alert
                                 onClose={() => setturnOnLocationMessage(false)}
                                 severity="warning"
                                 sx={{ width: "100%" }}>
                                 Please turn on location services to enable delivery
                              </Alert>
                           </Snackbar>
                           <div className="flex 2xl:gap-[8rem] ">
                              <StoreWrapper name={"Delivery"}>
                                 <ToggleSwitch
                                    noWidth
                                    handleValue={() => {
                                       if (isLocationBlocked) {
                                          setturnOnLocationMessage(true);
                                          getGeoLocation();
                                       } else {
                                          setFieldValue("delivery", !values.delivery);
                                       }
                                    }}
                                    isAvailable={values?.delivery}
                                 />
                              </StoreWrapper>
                              <div className="w-[50%]"></div>
                           </div>
                           <div className="my-[1.5rem]">
                              <Button
                                 Size="md"
                                 title={"Get current location"}
                                 background={"#4361EE"}
                                 color={"#ffff"}
                                 border="none"
                                 disabled={false}
                                 onClick={getGeoLocation}
                              />
                           </div>
                           <div className="flex justify-between 2xl:gap-[8rem] gap-[5rem]">
                              <div className="w-[50%]">
                                 <Input
                                    className="font-medium"
                                    label={"Latitude (optional)"}
                                    onChange={(e) => {
                                       setFieldValue("latitude", e.target.value);
                                    }}
                                    name="latitude"
                                    type="number"
                                 />{" "}
                              </div>
                              <div className="w-[50%]">
                                 <Input
                                    className="font-medium"
                                    label={"Longitude (optional)"}
                                    onChange={(e) => {
                                       setFieldValue("longitude", e.target.value);
                                    }}
                                    name="longitude"
                                    type="number"
                                 />
                              </div>
                           </div>
                           <div className="flex justify-between 2xl:gap-[8rem] gap-[5rem] mt-[1.5rem]">
                              <div className="w-[50%]">
                                 <Input
                                    className="font-medium"
                                    style={{ textAlign: "Left" }}
                                    customPlaceholder={"Kms"}
                                    label={"Delivery radius (optional)"}
                                    name="delivery_radius"
                                    type="number"
                                    // placeholder="Enter delivery threshold"
                                    onChange={(e) => {
                                       setFieldValue("delivery_radius", e.target.value);
                                    }}
                                 />
                              </div>
                              <div className="w-[50%]"></div>
                           </div>
                        </TabPanel>
                        <TabPanel
                           value={value}
                           index={1}>
                           <div className="flex flex-col gap-4  w-full">
                              <div className="flex 2xl:gap-[8rem] ">
                                 {merchantDetails.country === "India" && (
                                    <StoreWrapper name={"Show menu only"}>
                                       <ToggleSwitch
                                          noWidth
                                          handleValue={() => {
                                             setFieldValue("show_menu_only", !values.show_menu_only);
                                          }}
                                          disabled={merchantDetails.country !== "India"}
                                          isAvailable={values?.show_menu_only}
                                       />
                                    </StoreWrapper>
                                 )}
                                 <StoreWrapper name={"Accept Orders"}>
                                    <ToggleSwitch
                                       noWidth
                                       handleValue={() => {
                                          setFieldValue("accept_orders", !values.accept_orders);
                                       }}
                                       disabled={values.show_menu_only}
                                       isAvailable={values.show_menu_only ? false : values?.accept_orders}
                                    />
                                 </StoreWrapper>
                              </div>
                              <div className="flex 2xl:gap-[8rem] ">
                                 {merchantDetails.country === "India" && (
                                    <StoreWrapper name={"Include GST"}>
                                       <ToggleSwitch
                                          noWidth
                                          handleValue={() => {
                                             setFieldValue("include_gst", !values?.include_gst);
                                          }}
                                          disabled={values.show_menu_only}
                                          isAvailable={values.show_menu_only ? false : values?.include_gst}
                                       />
                                    </StoreWrapper>
                                 )}
                                 <StoreWrapper name={"Show item count"}>
                                    <ToggleSwitch
                                       noWidth
                                       handleValue={() => {
                                          setFieldValue("show_available_item_count", !values.show_available_item_count);
                                       }}
                                       disabled={values.show_menu_only}
                                       isAvailable={values.show_menu_only ? false : values?.show_available_item_count}
                                    />
                                 </StoreWrapper>
                              </div>
                              <div className="flex 2xl:gap-[8rem] ">
                                 <StoreWrapper name={"Dine in"}>
                                    <ToggleSwitch
                                       noWidth
                                       handleValue={() => {
                                          setFieldValue("dine_in", !values.dine_in);
                                       }}
                                       disabled={values.show_menu_only}
                                       isAvailable={values.show_menu_only ? false : values.dine_in}
                                    />
                                 </StoreWrapper>
                                 {false && (
                                    <StoreWrapper name={"Take away"}>
                                       <div className="flex items-center">
                                          <ToggleSwitch
                                             noWidth
                                             handleValue={() => {
                                                setFieldValue("take_away", !values.take_away);
                                             }}
                                             disabled={merchantDetails.country !== "India" || values.show_menu_only}
                                             isAvailable={values.show_menu_only ? false : values?.take_away}
                                          />
                                          {merchantDetails.country !== "India" && (
                                             <Tooltip title="Takeaways are disabled by default. To enable it, please reach out to support@greyfeathers.in">
                                                <InfoIcon style={{ marginBottom: "0.4rem", fontSize: "1.1rem", color: "grey" }} />
                                             </Tooltip>
                                          )}
                                       </div>
                                    </StoreWrapper>
                                 )}
                              </div>
                           </div>
                        </TabPanel>
                        <div className="ButtonWrapper">
                           {Merchant ? (
                              <Button
                                 Size="md"
                                 title={"Edit Merchant"}
                                 background={"#4361EE"}
                                 color={"#ffff"}
                                 border="none"
                                 icon={<EditIcon />}
                                 disabled={!isValid}
                              />
                           ) : (
                              <Button
                                 Size="md"
                                 title={"Update"}
                                 background={"#2D6B4D"}
                                 color={"#ffff"}
                                 border="none"
                                 type="submit"
                              />
                           )}
                        </div>
                     </Form>
                  );
               }}
            </Formik>
         </div>
      </Layout>
   );
}

export default Profile;
