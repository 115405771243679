/** @format */

import React from "react";

const StoreWrapper = ({ name, children }) => {
   return (
      <div className="w-[45%]">
         <div className="flex border p-[1.3rem] rounded-xl justify-between items-center">
            <p className="text-[1.2rem] text-[#868B8C]">{name}</p>
            {children}
         </div>
      </div>
   );
};

export default StoreWrapper;
