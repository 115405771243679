/** @format */

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BreadCrumbs from "components/BreadCrumbs";
import CartItem from "components/CartItem";
import CategoryItemCard from "components/CategoryItem";
import FoodItemCard from "components/FoodItem/index";
import Loader from "components/Loader";
import ManualOrderSummaryModal from "components/ManualOrderSummaryModal";
import NoItems from "components/NoItems";
import Search from "components/Search";
import Layout from "layouts";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as MenuActions from "store/action/MenuAction";
import { GetMerchant } from "store/action/MerchantAction";
import { addOrRemoveItemFromCart } from "store/reducer/ManualOrderReducer";
import { CGST_PERCENTAGE, SGST_PERCENTAGE } from "utils/definitions";
import { CreateManualOrder, UpdateManualOrder } from "store/action/ManualOrderAction";
import OrderActionModal from "components/OrderActionModal";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { resetCart } from "store/reducer/ManualOrderReducer";
import "./style.scss";
import { getCurrency } from "utils/functions";

const breadCrumbsData = [
   {
      title: "All orders",
      path: "/orders",
   },
   {
      title: "Create order",
      path: "/orders/create",
   },
];

function ManualOrder() {
   const allItems = useSelector((state) => state.MenuReducer.Menu);
   const { Cart, order } = useSelector((state) => state.ManualOrderReducer);
   const MerchantDetails = useSelector((state) => state.MerchantReducer.merchantDetails);
   const [showOrderActionModal, setshowOrderActionModal] = useState(false);

   const [loading, setloading] = useState(false);
   const [searchVal, setSearchVal] = useState([]);
   const [searchResults, setSearchResults] = useState([]);
   const [searchItemResults, setSearchItemResults] = useState([]);
   const [select, setSelect] = useState("");
   const [removedItems, setRemovedItems] = useState([]);
   const NewlyAddedItems = useSelector((state) => state?.NewlyAddedReducer?.newlyAddedItems?.newly_added_items);
   const [addedItems, setaddedItems] = useState(NewlyAddedItems);
   const [showOrderSummaryModal, setshowOrderSummaryModal] = useState(false);
   const [tableNumber, setTableNumber] = useState("");

   console.log("MerchantDetails", MerchantDetails);

   useEffect(() => {
      dispatch(GetMerchant());
   }, []);

   const FETCH_ITEMS = async (id) => {
      await allItems
         ?.filter((item) => item.id === id)
         .map((i) => {
            setSelect(i.category_name);
            localStorage.setItem("categoryRecommendedId", id);
         });
   };

   function removeDuplicates(arr) {
      let unique = [];
      for (const i in arr) {
         if (unique.indexOf(arr[i]) === -1) {
            unique.push(arr[i]);
         }
      }
      return unique;
   }

   const searchMenu = (input) => {
      let temp = [];
      let item = [];
      setSearchVal(input);
      if (input != "") {
         for (const i in allItems) {
            for (const j in allItems[i].items)
               if (allItems[i].category_name.toLowerCase().includes(input.toLowerCase()) || allItems[i].items[j].item_name.toLowerCase().includes(input.toLowerCase())) {
                  temp.push(allItems[i]);
                  item.push(allItems[i].items[j]);
                  console.log("Searched Item", item);
               }
         }
         if (temp.length > 0 || item.length > 0) {
            setSearchItemResults(removeDuplicates(item));
            setSearchResults(removeDuplicates(temp));
         } else {
            setSearchResults([]);
            setSearchItemResults([]);
         }
      }
   };
   const catId = localStorage.getItem("categoryRecommendedId");
   const FoodItems = allItems.filter((i) => i.id === catId).map((i) => i.items);
   const dispatch = useDispatch();
   const FETCH_ALL_ITEMS = async () => {
      setloading(true);
      await dispatch(MenuActions.GetMenu());
      setloading(false);
   };

   useEffect(() => {
      FETCH_ALL_ITEMS();
   }, []);

   useEffect(() => {
      FETCH_ITEMS(allItems[0]?.id);
   }, [allItems]);

   //AddItemsFunction
   const addOrRemoveItem = (item, type) => {
      dispatch(addOrRemoveItemFromCart({ item, type }));
   };

   const getCartTotal = () => {
      let total = 0;
      Cart.map((item) => {
         total += item.total_price;
      });
      return total;
   };

   const generateOrderPayload = () => {
      const { include_gst } = MerchantDetails;
      const TOTAL_CART_AMOUNT = getCartTotal();

      let FINAL_AMOUNT = TOTAL_CART_AMOUNT;
      if (include_gst) {
         FINAL_AMOUNT = TOTAL_CART_AMOUNT + TOTAL_CART_AMOUNT * (CGST_PERCENTAGE * 0.01) + TOTAL_CART_AMOUNT * (SGST_PERCENTAGE * 0.01);
      }
      FINAL_AMOUNT = parseFloat(FINAL_AMOUNT).toFixed(2);
      const AFTER_ROUNDED_OFF_AMOUNT = Math.round(FINAL_AMOUNT);
      const ROUNDED_OFF = (AFTER_ROUNDED_OFF_AMOUNT - FINAL_AMOUNT).toFixed(2);
      const subOrderPayload = {
         suborder_status: "new_order",
         suborder_items_ordered: Cart,
         total_suborder_amount: TOTAL_CART_AMOUNT,
         total_suborder_amount_offers_applied: TOTAL_CART_AMOUNT,
         offers_applied: [],
         loyalty_points_used: 0,
         offers_discount_amount: 0,
         loyalty_discount_amount: 0,
      };
      const payload = {
         items_ordered: [subOrderPayload],
         qr_code_id: "",
         order_discount: 0.0,
         total_amount: TOTAL_CART_AMOUNT,
         total_amount_after_discount: TOTAL_CART_AMOUNT - TOTAL_CART_AMOUNT * (0.0 * 0.01),
         order_type: "Manual",
         payment_status: "un-paid",
         order_status: "new_order",
         refund_amount: 0,
         order_created_by: "merchant",
         cgst_percentage: include_gst ? CGST_PERCENTAGE : 0,
         sgst_percentage: include_gst ? SGST_PERCENTAGE : 0,
         rounded_off: ROUNDED_OFF,
         amount_after_taxes: FINAL_AMOUNT,
         waiter_id: localStorage.getItem("waiter_id"),
         table_number: tableNumber,
      };
      console.log("generateOrderPayload", payload);
      return payload;
   };

   const onCreateOrder = async () => {
      setloading(true);
      const data = generateOrderPayload();
      const result = await dispatch(CreateManualOrder(data));
      if (result.type === "CreateManualOrder/fulfilled") {
         toast.success("Order created successfully");
         console.log("onCreateOrder", result);
         setloading(false);
         setshowOrderSummaryModal(false);
         setshowOrderActionModal(true);
      } else {
         setloading(false);
         toast.error("Something went wrong");
      }
   };

   const onOrderComplete = async (payment_method, payment_notes) => {
      setloading(true);
      const payload = { ...order, order_status: "order_completed", payment_method, payment_notes };
      const result = await dispatch(UpdateManualOrder(payload));
      if (result.type === "UpdateManualOrder/fulfilled") {
         toast.success("Order completed sucessfully");
         dispatch(resetCart());
         setloading(false);
         setshowOrderActionModal(false);
      } else {
         setloading(false);
         toast.error("Something went wrong");
      }
   };

   const getPrintData = () => {
      if (order?.items_ordered) {
         return order?.items_ordered[0]?.suborder_items_ordered;
      }
      return [];
   };

   const handleTableNumber = (e) => {
      setTableNumber(e.target.value);
   };

   return (
      <Layout>
         <Loader isLoading={loading} />
         <ManualOrderSummaryModal
            data={generateOrderPayload()}
            visible={showOrderSummaryModal}
            onCreateOrder={onCreateOrder}
            onClose={() => setshowOrderSummaryModal(false)}
            tableNumber={tableNumber}
            UpateTableNumber={(e) => handleTableNumber(e)}
         />
         <OrderActionModal
            onClose={() => {
               dispatch(resetCart());
               setshowOrderActionModal(false);
            }}
            visible={showOrderActionModal}
            onOrderComplete={onOrderComplete}
            data={{ ...order, items_ordered: getPrintData() }}
         />
         <div className="recommended-container">
            <div className="recommended-container-heading">
               <BreadCrumbs data={breadCrumbsData} />
            </div>
            <div className="recommended-content-container">
               <div className="recommended-categories-added-items-search-wrapper">
                  <Search
                     onChange={(e) => {
                        searchMenu(e.target.value);
                     }}
                     border={"1px solid rgba(0,0,0,0.3)"}
                     InputHeight={"3rem"}
                     padding={"0.8rem"}
                     width={"100%"}
                     placeholder={"Search in menu"}
                  />

                  <div className="recommended-categoies-added-items-main-container">
                     <div className="recommended-categories-container">
                        <div className="recommended-category-items-wrapper">
                           {searchVal != ""
                              ? searchResults?.map((item, index) => (
                                   <CategoryItemCard
                                      item={item}
                                      select={select}
                                      name={item.category_name}
                                      filter={FETCH_ITEMS}
                                      key={index}
                                   />
                                ))
                              : allItems?.map((item, index) => (
                                   <CategoryItemCard
                                      item={item}
                                      select={select}
                                      name={item.category_name}
                                      filter={FETCH_ITEMS}
                                      key={index}
                                   />
                                ))}
                        </div>
                     </div>
                     {
                        <div className="recommended-items-container">
                           <div className="recommended-add-items-wrapper">
                              {searchVal != ""
                                 ? searchItemResults?.map((item, index) => (
                                      <FoodItemCard
                                         item={item}
                                         key={index}
                                         isAdded={addedItems?.findIndex((i) => item.item_id === i.item_id) != -1 ? true : false}
                                         addToRecommended={() => {
                                            addOrRemoveItem(item, "add");
                                         }}
                                         disabled={addedItems?.length === 5 ? true : false}
                                      />
                                   ))
                                 : FoodItems[0]?.map((item, index) => (
                                      <FoodItemCard
                                         item={item}
                                         key={index}
                                         isAdded={addedItems?.findIndex((i) => item.item_id === i.item_id) != -1 ? true : false}
                                         disabled={addedItems?.length === 5 ? true : false}
                                         addToRecommended={() => {
                                            addOrRemoveItem(item, "add");
                                         }}
                                      />
                                   ))}
                           </div>
                        </div>
                     }
                  </div>
               </div>
               <div className="recommended-added-items-container">
                  {(addedItems?.length === 5 || NewlyAddedItems?.length === 5) && <span className="recommended-items-warning">You can add only upto 5 items</span>}
                  <div className="recommended-added-items-container-header">
                     <span className="recommended-added-items-heading-text1">Create order</span>
                  </div>
                  <div className={Cart?.length === 0 ? "recommended-added-items-added-items-container NoItemsWrapper" : "recommended-added-items-added-items-container"}>
                     {Cart?.length > 0 ? (
                        Cart?.map((item, index) => {
                           return (
                              <CartItem
                                 item={item}
                                 onClickActionButtons={(type) => addOrRemoveItem(item, type)}
                              />
                           );
                        })
                     ) : (
                        <NoItems />
                     )}
                  </div>
                  {Cart?.length > 0 && (
                     <div className="flex justify-between border-t-[1px] border-[#c4c4ca] border-solid">
                        <div className="flex flex-col items-center p-1 ml-1">
                           <div>Total items</div>
                           <div className="text-[#2D6B4D]">{Cart?.length}</div>
                        </div>
                        <div className="flex flex-col items-center p-1 mr-1">
                           <div>Total cost</div>
                           <div className="text-[#2D6B4D]">
                              {getCurrency(MerchantDetails?.currency)} {getCartTotal()}
                           </div>
                        </div>
                        <div
                           onClick={() => setshowOrderSummaryModal(true)}
                           className="bg-[#2D6B4D] flex items-center px-2 gap-1 cursor-pointer text-[#fff]">
                           <div>Create order</div>
                           <ArrowForwardIcon />
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </Layout>
   );
}

export default ManualOrder;
